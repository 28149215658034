/* index.css */
.spinner {
    --d: 90px; /* Adjust this value to control the size of the bubbles */
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #E31837;
    box-shadow: 
      calc(1*var(--d))      calc(0*var(--d))     0 0,
      calc(0.866*var(--d))  calc(0.5*var(--d))   0 1.1px,
      calc(0.5*var(--d))    calc(0.866*var(--d)) 0 2.2px,
      calc(0*var(--d))      calc(1*var(--d))     0 3.4px,
      calc(-0.5*var(--d))   calc(0.866*var(--d)) 0 4.5px,
      calc(-0.866*var(--d)) calc(0.5*var(--d))   0 5.6px,
      calc(-1*var(--d))     calc(0*var(--d))     0 6.7px,
      calc(-0.866*var(--d)) calc(-0.5*var(--d))  0 7.8px,
      calc(-0.5*var(--d))   calc(-0.866*var(--d))0 8.9px,
      calc(0*var(--d))      calc(-1*var(--d))    0 10px,
      calc(0.5*var(--d))    calc(-0.866*var(--d))0 11.1px,
      calc(0.866*var(--d))  calc(-0.5*var(--d))  0 12.2px;
      animation: spinner-a90wxe 1s infinite steps(8);
    }
  
  @keyframes spinner-a90wxe {
    100% {
      transform: rotate(1turn);
    }
  }
  