.pulse,.pulse-off{
    height: 160px;
    width: 160px;
    background: white;
    margin:auto;
    border-radius: 50%;
    display: grid;
    place-items: center;
    color: #ffffff;
}
.pulse:before,
.pulse:after{
    content: "";
    position: absolute;
    height: 160px;
    width: 160px;
    background-color:rgba(227, 24, 55);
    border-radius: 50%;
    z-index: 0;
    opacity: 0.7;
}
.pulse:before{
    animation: pulse 2s ease-out infinite;
}
.pulse:after{
    animation: pulse 2s 1s ease-out infinite;
}
@keyframes pulse{
    100%{
        transform: scale(1.5);
        opacity: 0;
    }
}