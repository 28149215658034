@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  @font-face {
    font-family: Avenir;
    src: url(../public/fonts/Avenir-Regular.ttf);
  }

  html {
    font-family: 'Avenir', 'Work Sans', sans-serif !important;
  }

}

@layer utilities {
  .scrollbar-ielts::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .scrollbar-ielts::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .scrollbar-ielts::-webkit-scrollbar-thumb {
    background: #E3E5E9;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar-ielts::-webkit-scrollbar-thumb:hover {
    background: #E3E5E9;
  }
}

.submitLoader {
  width: 64px;
  aspect-ratio: 1;
  background: 
    radial-gradient(farthest-side at bottom right,#F10C49 90%,#0000),
    radial-gradient(farthest-side at bottom left ,#F10C49 90%,#0000),
    radial-gradient(farthest-side at top    right,#F10C49 90%,#0000),
    radial-gradient(farthest-side at top    left ,#F10C49 90%,#0000),
    radial-gradient(farthest-side at bottom right,#f4dd51 90%,#0000),
    radial-gradient(farthest-side at bottom left ,#f4dd51 90%,#0000),
    radial-gradient(farthest-side at top    right,#f4dd51 90%,#0000),
    radial-gradient(farthest-side at top    left ,#f4dd51 90%,#0000),
    radial-gradient(farthest-side at bottom right,#e3aad6 90%,#0000),
    radial-gradient(farthest-side at bottom left ,#e3aad6 90%,#0000),
    radial-gradient(farthest-side at top    right,#e3aad6 90%,#0000),
    radial-gradient(farthest-side at top    left ,#e3aad6 90%,#0000);
  background-size: 25% 25%;
  background-repeat: no-repeat;
  animation: l16 2s infinite;
}
@keyframes l16{
  0% {background-position: 
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3)}
  16.67% {background-position: 
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3)}
  33.33% {background-position: 
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3),
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3)}
  50% {background-position: 
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3),
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3),
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3)}
  66.67% {background-position: 
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3),
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3)}
  83.33% {background-position: 
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(0*100%/3) calc(0*100%/3), calc(3*100%/3) calc(0*100%/3), calc(0*100%/3) calc(3*100%/3), calc(3*100%/3) calc(3*100%/3)}
  100% {background-position: 
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3),
        calc(1*100%/3) calc(1*100%/3), calc(2*100%/3) calc(1*100%/3), calc(1*100%/3) calc(2*100%/3), calc(2*100%/3) calc(2*100%/3)}
}

.buttonLoader {
      width: fit-content;
      font-weight: bold;
      font-family: monospace;
      font-size: 20px;
      color: #0000;
      background: linear-gradient(90deg,#C02942 calc(50% + 0.5ch),#000 0) right/calc(200% + 1ch) 100%;
      -webkit-background-clip: text;
              background-clip: text;
      animation: l7 2s infinite steps(11);
    }
    .buttonLoader:before {
      content:"Loading..."
    }
    @keyframes l7 {to{background-position: left}}

    .directLoader {
      width: fit-content;
      font-weight: bold;
      font-family: monospace;
      font-size: 20px;
      padding-top: 5px;
      color: #0000;
      background: linear-gradient(90deg,#C02942 calc(50% + 0.5ch),#000 0) right/calc(200% + 1ch) 100%;
      -webkit-background-clip: text;
              background-clip: text;
      animation: l7 2s infinite steps(11);
    }
    .directLoader:before {
      content:"Redirecting to IELTS"
    }
    @keyframes l7 {to{background-position: left}}

    .shimmer {
      background: linear-gradient(to right, #f0f0f0, #e0e0e0, #f0f0f0);
      background-size: 200% auto;
      animation: shimmer 2.5s infinite linear;
  }
  
  @keyframes shimmer {
      0% {
          background-position: 200% 0;
      }
      100% {
          background-position: -200% 0;
      }
  }

  @keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

  .marquee-container {
    display: flex;
    flex-direction: row;
  }

  .marquee-content {
    animation: marquee 7.5s linear infinite;
    display: flex;
    white-space: nowrap;
  }

.marquee-item {
  color: #1976d2;
  margin-right: 4rem;
  font-size: large;
}

.redirect-loading {
  width: calc(80px / cos(45deg));
  height: 14px;
  --c: #000 0 15px,#0000 0 20px;
  background: 
    repeating-linear-gradient(135deg,var(--c)) left top,
    repeating-linear-gradient( 45deg,var(--c)) left bottom;
  background-size:  200% 50%;
  background-repeat: no-repeat;
  animation: l4 2s infinite linear;
}

@keyframes l4 {
  100% {background-position:top right,bottom right}
}