@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: Avenir;
      src: url(./../public/fonts/Avenir-Regular.ttf);
    }
  
    html {
      font-family: 'Avenir', 'Work Sans', sans-serif;
    }
  
  }

  textarea::placeholder {
    padding: 10px; /* Adjust the padding value as needed */
    /* Add other styles for the placeholder if desired */
  }

.react-tel-input .form-control {
    width: 100% !important;
    border: none;
    height: 48px;
    background-color: transparent;
}

.react-tel-input {
    border-radius: 30px;
    border: 1px solid rgba(227, 24, 55, 0.50);
    background: var(--neutral-white, #FFF);
}
.react-tel-input .flag-dropdown.open {
    border: none !important;
    background-color: transparent !important;
    margin: 5px 10px;
}

.react-tel-input .flag-dropdown {
    border: none !important;
    background-color: transparent !important;
    margin: 0px 10px;
}