.progressbar{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 16px;
    border-radius: 16px;
    background-color: #E3E5E9;
}
.progressbarbg{
        height: 100%;
        background-color: #E31837;
        border-radius: 16px;
        
}